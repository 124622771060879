@import '~antd/dist/antd.less';

body {
  padding: 20px;
}

.list-form {
  margin: 40px auto;
  max-width: 400px;
}

.language-switcher {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the buttons */
  margin: 20px auto; /* Adjusted margin */
  max-width: 400px;
  button {
    font-size: 10pt;
    color: #aaa; /* Subtle text color */
    background-color: transparent; /* No background for a lighter feel */
    border: 1px solid transparent; /* Subtle border */
    border-radius: 3px; /* Rounded corners for a modern look */
    padding: 2px 4px; /* Padding for button size */
    margin: 4px; /* Space between buttons */
    cursor: pointer; /* Change cursor to indicate it's clickable */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  button:hover,
  button:focus {
    color: #555; /* Change color on hover/focus */
    //border-color: #888; /* Darker border on hover/focus */
  }
  button.active {
    //color: #fff; /* Active button text color */
    color: #555;
    //border-color: #555;
    background-color: #eee;
  }
}


